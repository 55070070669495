import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { message } from "antd";

// Create the context
const AppContext = createContext();

// Custom hook to use the context
export const useAppContext = () => {
  return useContext(AppContext);
};

// Initial state
const initialState = {
  user: null,
};

// Context provider component
export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const authHdrRef = useRef({});

  const [authenticated, setAuthenticated] = useState(false);
  // Base URL variable
  console.log("Env url ::: ", process.env["REACT_APP_API_HOST"]);
  const baseUrl = process.env["REACT_APP_API_HOST"];

  useEffect(() => {
    let localStorageAuth = localStorage.getItem("roo");
    if (localStorageAuth) {
      authHdrRef.current = {
        authorization: localStorageAuth,
        "Content-Type": "application/json",
      };
      setAuthenticated(true);
    }
  }, []);

  // Fetch domains and proxies at startup
  useEffect(() => {
    if (baseUrl && authenticated) {
    }
  }, [baseUrl, authenticated]);

  // Context value
  const contextValue = {};

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
