import Layout from "antd/es/layout/layout";
import { Outlet, useRoutes } from "react-router-dom";
import PageLayout from "./PageLayout";
// import DomainsPage from "./pages/domains";
import LandingPage from "./pages/LandingPage";
export default function AppRouter() {
  return useRoutes([
    {
      path: "/",
      element: (
        <Layout>
          {/* <PageLayout> */}
          <Outlet />
          {/* </PageLayout> */}
        </Layout>
      ),
      children: [
        {
          path: "/",
          element: <LandingPage />,
          // element: <DomainsPage />,
        },
      ],
    },
  ]);
}
