import { Footer } from "antd/es/layout/layout";

const FooterComponent = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      Version: 1.0/2024
    </Footer>
  );
};

export default FooterComponent;
