import { Button, Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./pages/app";

const Navigation = () => {
  const navigateTo = useNavigate();
  const appCtx = useAppContext();

  const items = [
    {
      key: "domains",
      label: "Domains",
      onClick: (e) => {
        navigateTo("/domains");
      },
    },
    {
      key: "proxies",
      label: "Proxies",
      onClick: (e) => {
        navigateTo("/proxies");
      },
    },
    {
      key: "setting",
      label: "Settings",
      onClick: (e) => {
        navigateTo("/setting");
      },
    },
  ];

  const getActiveEl = (e) => {
    if (window.location.pathname === "/") {
      return "domains";
    }
    return window.location.pathname.replace("/", "");
  };

  const handleLogout = (e) => {
    let c = window.confirm("Are you sure to logout?");
    if(c) {
      appCtx.logout();
    }
  }

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[getActiveEl()]}
        items={items}
        style={{
          flex: 1,
          minWidth: 0,
        }}
      />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
      <Button style={{marginRight: 20}} onClick={handleLogout} ghost={true}>Signout</Button>
        <img height={44} src="/favicon.png" />
        {/* <span style={{ color: "#ffffff", marginLeft: 16, fontWeight: 600, fontSize: 20 }}>247roo</span> */}
      </div>
    </Header>
  );
};

export default Navigation;
