import { Content } from "antd/es/layout/layout";
import Navigation from "./Navigation";
import FooterComponent from "./Footer";

const PageLayout = ({ children }) => {
  return (
    <>
      <Navigation />
      <Content
        style={{
          padding: "20px 48px",
          height: "calc(100vh - 60px)",
        }}
      >
        {children}
      </Content>
      <FooterComponent />
    </>
  );
};

export default PageLayout;
